import { useEffect, useState } from "react";

// inspire https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open
const useBlockScroll = ({ isModalOpen, backToCurrentPosition = true }) => {
	const [currentPosition, setCurrentPosition] = useState(0);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			document.documentElement.style.setProperty("--scroll-y", `${window.scrollY}px`);
		});

		if (isModalOpen) {
			const currentScrollY = document.documentElement.style.getPropertyValue("--scroll-y");
			setCurrentPosition(parseInt(currentScrollY || "0", 10));
			document.body.style.position = "fixed";
			document.body.style.right = 0;
			document.body.style.left = 0;
			document.body.style.inset = `-${currentScrollY} 0px 0px`;
		} else {
			document.body.style.removeProperty("position");
			document.body.style.removeProperty("inset");
			document.body.style.right = "initial";
			document.body.style.left = "initial";
			if (backToCurrentPosition) {
				window.scrollTo(0, currentPosition);
			}
		}

		return () => {
			document.body.style.removeProperty("position");
			document.body.style.removeProperty("inset");
			document.body.style.right = "initial";
			document.body.style.left = "initial";
			if (window) {
				window.removeEventListener("scroll", () => {});
			}
		};
	}, [isModalOpen]);
};

export default useBlockScroll;
