import { createSelector } from "reselect";
import get from "lodash/get";
import uniq from "lodash/uniq";
import flatten from "lodash/flatten";
import { getMerchPreviewStartDate } from "app/pages/Merchandising/merchandisingSelector";
import { zonedTimeToUtc } from "date-fns-tz";
import { PAYMENT_METHODS, SDP_SEARCH_MARKETING_BLOCKS, WEBSITE_TYPE } from "app/constants";
import isValid from "date-fns/isValid";

export const getPartner = state => state.partner;

export const getPartnerCode = state => state.partner && state.partner.code;

export const getReasonToBookList = state => state.partner && state.partner.reasonToBookList;
export const getReasonToBookListGeneric = state =>
	state.partner && state.partner.reasonToBookListGeneric;

export const getFallbackRedirectUrl = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.fallbackUrl;
	}
);

export const shouldUseBrowserBackOnProductPage = createSelector(
	[getPartner],
	(partner = {}) => {
		return Boolean(partner.useBrowserBackOnProductPage);
	}
);

export const getBrandDisplayName = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.brandDisplayName || "";
	}
);

export const getPartnerMarketing = createSelector(
	[getPartner],
	partner => {
		return get(partner, "marketing", {});
	}
);

export const getPartnerMarketingHomeData = createSelector(
	[getPartnerMarketing],
	(marketing = {}) => {
		return marketing?.home || {};
	}
);

export const getPartnerAuthText = createSelector(
	[getPartner],
	partner => {
		return get(partner, "marketing.authLandingText", {});
	}
);

export const getPartnerBookingAuthText = createSelector(
	[getPartner],
	partner => {
		return get(partner, "marketing.bookingAuthLandingText", {});
	}
);

export const getPartnerListingHero = createSelector(
	[getPartner],
	partner => {
		return get(partner, "marketing.listingHero", {});
	}
);

export const getWebsiteType = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner?.websiteType || WEBSITE_TYPE.LISTING;
	}
);

export const getPartnerAuthLayer = createSelector(
	[getPartner],
	partner => {
		return get(partner, "marketing.authLayer", {});
	}
);

export const getPartnerListingSEO = createSelector(
	[getPartner],
	partner => {
		return get(partner, "marketing.listingHero.seo", {});
	}
);

export const getPartnerMarketingHomeBlocks = createSelector(
	[getPartnerMarketing, getMerchPreviewStartDate],
	(marketing = {}, previewStartDate) => {
		// previewStartDate est valorisé par la query param ?preview=YYYYMMDD
		const previewDateTimestamp = zonedTimeToUtc(previewStartDate || new Date(), "YYYYMMDD");

		const todayTimestamp = new Date().getTime();

		return marketing?.homeBlocks?.filter(block => {
			if (block._type === SDP_SEARCH_MARKETING_BLOCKS.ONE_MERCH_BANNER) {
				const merchEndAtTimestamp = new Date(block.endAt).getTime();
				const merchStartAtTimestamp = new Date(block.startAt).getTime();

				if (isValid(previewDateTimestamp)) {
					return !(
						previewDateTimestamp >= merchEndAtTimestamp ||
						previewDateTimestamp < merchStartAtTimestamp
					);
				}

				// do not return expired push notification
				return merchEndAtTimestamp >= todayTimestamp;
			}
			return true;
		});
	}
);

export const hasAlmaPayment = createSelector(
	[getPartner],
	(partner = {}) => {
		const paymentMethods = partner.availablePaymentMethods || [];

		return paymentMethods.some(paymentMethod => paymentMethod.code === PAYMENT_METHODS.ALMA);
	}
);

export const getAvailablePaymentTerms = createSelector(
	[getPartner],
	partner => {
		const paymentMethods = partner.availablePaymentMethods || [];

		const paymentTerms = paymentMethods.map(paymentMethod => {
			return paymentMethod.termsChoices;
		});

		return uniq(flatten(paymentTerms));
	}
);

export const checkIsWithoutFee = createSelector(
	[getPartner],
	partner => {
		const paymentMethods = partner.availablePaymentMethods || [];
		const feesFirstRateRanges = paymentMethods
			.flatMap(method => method?.fees?.rateRanges[0])
			.filter(Boolean); // remove all undefined after flatMap
		if (feesFirstRateRanges.length > 0) {
			return feesFirstRateRanges.every(fee => fee.feePercentage === 0);
		}
		return false;
	}
);

export const getMetaTags = createSelector(
	[getPartner],
	partner => {
		return get(partner, "metaTags", []);
	}
);

export const getTheme = createSelector(
	[getPartner],
	partner => {
		return get(partner, "theme", {});
	}
);

export const getFooterData = createSelector(
	[getPartner],
	partner => {
		return get(partner, "footer", {});
	}
);

export const getRobotsMetaTag = createSelector(
	[getPartner],
	(partner = {}) => {
		return {
			default: "noindex, follow",
			listing: "noindex, follow",
			homeLoginSignup: "noindex, follow",
			product: "noindex, follow",
			merch: "noindex, follow",
			sdpSearch: "noindex, follow",
			...partner.robotsMetaTag,
		};
	}
);

export const getShowTransportOfferOnly = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.showTransportOfferOnly;
	}
);

export const getHomeImages = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.home;
	}
);

export const getShowPfsMessage = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner.showPfsMessage;
	}
);

export const getMenuConnectMessages = createSelector(
	[getPartnerMarketing],
	(marketing = {}) => {
		return marketing.menuConnectMessages;
	}
);

export const getMarketingBanners = createSelector(
	[getPartnerMarketing],
	(marketing = {}) => {
		return marketing.marketingBanners;
	}
);

export const getFilteredMarketingBanners = createSelector(
	[getMarketingBanners, getMerchPreviewStartDate],
	(marketingBanners = [], previewStartDate) => {
		// previewStartDate est valorisé par la query param ?preview=YYYYMMDD
		const previewDateTimestamp = zonedTimeToUtc(previewStartDate || new Date(), "YYYYMMDD");

		const todayTimestamp = new Date().getTime();
		return marketingBanners.filter(banner => {
			const endAt = new Date(banner.endAt).getTime();
			const startAt = new Date(banner.startAt).getTime();
			if (isValid(previewDateTimestamp)) {
				return !(previewDateTimestamp >= endAt || previewDateTimestamp < startAt);
			}
			// do not return expired push notification
			return endAt >= todayTimestamp;
		});
	}
);

export const getPartnerDepositPercentage = createSelector(
	[getPartner],
	(partner = {}) => {
		const paymentMethods = partner.availablePaymentMethods || [];
		const paymentByCard = paymentMethods.find(methode => methode.code === PAYMENT_METHODS.CARD);
		return paymentByCard?.onlinePercentage;
	}
);

export const checkIsCreditUsageIsEnabled = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner?.enableCredits;
	}
);

export const checkIsCreditNoteUsageIsEnabled = createSelector(
	[getPartner],
	(partner = {}) => {
		return partner?.enableCreditNotes;
	}
);

export const getComputedReasonToBookList = createSelector(
	[getReasonToBookList, getReasonToBookListGeneric],
	(reasonToBook = {}, reasonToBookGeneric = {}) => {
		const defaultTitle = reasonToBook?.defaultTitle || reasonToBookGeneric?.defaultTitle;
		const signupTitle = reasonToBook?.signupTitle || reasonToBookGeneric?.signupTitle;
		const items =
			reasonToBook?.items?.length > 0 ? reasonToBook?.items : reasonToBookGeneric?.items;
		return {
			...(reasonToBook?.isHidden && { isHidden: reasonToBook?.isHidden }),
			...(defaultTitle && { defaultTitle }),
			...(signupTitle && { signupTitle }),
			...(items?.length > 0 && { items }),
		};
	}
);

export const shouldShowReasonToBook = createSelector(
	[getReasonToBookList],
	(reasonToBook = {}) => {
		const { isHidden = false } = reasonToBook;
		return !isHidden;
	}
);

export const shouldShowTimer = createSelector(
	[getPartnerCode],
	partnerCode => {
		return partnerCode !== "VPDE" && partnerCode !== "PSVP" && !partnerCode.includes("SVUM");
	}
);
